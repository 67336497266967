import React, { useState } from "react";
import { FaWhatsapp } from "react-icons/fa6";
import { Link } from "react-router-dom";
import "../Footer/Footer.css";
import Whatsapp from "../Whatsapp/Whatsapp";

const Footer = () => {
  const [contactDetails, setContactDetails] = useState("");
  const [submitted, setSubmitted] = useState(false);

  const links = [
    [
      { label: "Company", key: "header-1" },
      { label: "About us", key: "item-1-1", to: "/about" },
      { label: "Blog", key: "item-1-2", to: "/blog" },
      { label: "Contact us", key: "item-1-3", to: "/contact" },
      { label: "Pricing", key: "item-1-4", to: "/pricing" },
      { label: "Testimonials", key: "item-1-5", to: "/#testimonialSection" },
    ],
    [
      { label: "Support", key: "header-2" },
      { label: "Help center", key: "item-2-1", to: "/help-center" },
      { label: "Terms of service", key: "item-2-2", to: "/terms" },
      { label: "Legal", key: "item-2-3", to: "/legal" },
      { label: "Privacy policy", key: "item-2-4", to: "/privacy-policy" },
      { label: "Status", key: "item-2-5", to: "/status" },
    ],
  ];
  const handleSubmit = (e) => {
    e.preventDefault();
    if (contactDetails) {
      setSubmitted(true);
      // Logic to send the contact details to an email or WhatsApp
      sendEmail(contactDetails);
      sendWhatsapp(contactDetails);
    }
  };

  const sendEmail = (contact) => {
    // Use a service like EmailJS or a backend endpoint to send the email
    console.log("Sending email with contact details:", contact);
    // EmailJS example (setup EmailJS in your project)
    // emailjs.send('YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', { contact }, 'YOUR_USER_ID')
    //   .then(response => console.log('Email sent successfully:', response))
    //   .catch(error => console.log('Failed to send email:', error));
  };

  const sendWhatsapp = (contact) => {
    const phoneNumber = "8448985128";
    const message = `Hello, the user entered the following contact details: ${contact}`;
    const whatsappLink = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
      message
    )}`;
    window.open(whatsappLink, "_blank");
  };

  return (
    <div className="footer">
      <div className="footer-company-info">
        <div className="footer-img">
          <img src="/images/hf-icon.png" className="hf-icon" alt="" />
          <span>HEALTHFLIX LLC</span>
        </div>
        <div className="ownerInfos">
          <span>RUCHIKA VERMA</span>
          <span>30 NGOULD ST STE R SHERIDAN, WY 82801</span>
          <span>Employer Identification Number: 61-2212154</span>
        </div>

        <div className="infos">
          <span>Copyright © 2023 HEALTHFLIX LLC</span>
          <span>All rights reserved</span>
        </div>
        <div className="footer-icons">
          <Whatsapp>
            <FaWhatsapp />
          </Whatsapp>
        </div>
      </div>
      <div className="footer-links">
        {links.map((col, index) => (
          <ul className={`col col-${index + 1}`} key={`col-${index}`}>
            {col.map((link, index) => (
              <li key={`link-${col}-${index}`}>
                {link.label === "Pricing" ? (
                  <Whatsapp>{link.label}</Whatsapp>
                ) : (
                  <Link to={link.to}>{link.label}</Link>
                )}
              </li>
            ))}
          </ul>
        ))}
      </div>
      <div className="footer-form">
        <form onSubmit={handleSubmit}>
          <label htmlFor="contact">Stay up to date</label>
          <input
            type="text"
            name="contact"
            id="contact"
            placeholder="Enter email or phone number"
            value={contactDetails}
            onChange={(e) => setContactDetails(e.target.value)}
            required
          />
          <button type="submit">Submit</button>
        </form>
        {submitted && <p>Thank you for your submission!</p>}
      </div>
    </div>
  );
};

export default Footer;
