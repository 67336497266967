import { Typography } from "@mui/material";
import React from "react";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import "./TestimonialCard.css";
import StarIcon from "@mui/icons-material/Star";

const TestimonialCard = ({ comment = "comment", name = "name" }) => {
  return (
    <div className="testimonialCard">
      <div className="content">
        <Typography className="comment">{comment}</Typography>

        <div className="tmCardFooter">
          <StarIcon className="starIcon" />
          <StarIcon className="starIcon" />
          <StarIcon className="starIcon" />
          <StarIcon className="starIcon" />
          <StarIcon className="starIcon" />
          <AccountCircleIcon className="tmIcon" />
          <p>{name}</p>
        </div>
      </div>
    </div>
  );
};

export default TestimonialCard;
