import * as React from "react";
import {
  Typography,
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Menu,
  Container,
  Avatar,
  Button,
  Tooltip,
  MenuItem,
} from "@mui/material";
import { Menu as MenuIcon } from "@mui/icons-material";
import { Link } from "react-router-dom";
import "./ResponsiveAppBar.css";
import Whatsapp from "../Whatsapp/Whatsapp";

const pages = [
  { name: "Home", link: "/" },
  { name: "About us", link: "/about" },
  { name: "Pricing", link: "/pricing" },
  // { name: "Reviews", link: "/reviews" },
  // { name: "Support", link: "/support" },
  { name: "Contact us", link: "/contact" },
];
const settings = ["Profile", "Account", "Dashboard", "Logout"];

const ResponsiveAppBar: React.FC = () => {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <div>
      <Link to="/">
        <img src="/images/logo.png" alt="Logo" className="logo-img" />
      </Link>

      <AppBar className="appBar">
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "flex", md: "none" },
                justifyContent: "end",
              }}
            >
              <IconButton
                size="large"
                aria-label="open navigation menu"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                className="navMenu"
              >
                {pages.map((page) => (
                  <MenuItem key={page.name} onClick={handleCloseNavMenu}>
                    {page.name === "Pricing" ? (
                      <Whatsapp>
                        <Button
                          href="#"
                          sx={{ textDecoration: "none", color: "black" }}
                        >
                          {page.name}
                        </Button>
                      </Whatsapp>
                    ) : (
                      <Button
                        component={Link}
                        to={page.link}
                        sx={{ textDecoration: "none", color: "black" }}
                      >
                        {page.name}
                      </Button>
                    )}
                  </MenuItem>
                ))}
              </Menu>
            </Box>

            <Box
              className="navLinks"
              sx={{
                flexGrow: 1,
                display: { xs: "none", md: "flex" },
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              {pages.map((page) => (
                <Button
                  key={page.name}
                  component={page.name === "Pricing" ? "div" : Link}
                  to={page.link}
                  onClick={
                    page.name === "Pricing" ? undefined : handleCloseNavMenu
                  }
                  className="navButton"
                >
                  {page.name === "Pricing" ? (
                    <Whatsapp>{page.name}</Whatsapp>
                  ) : (
                    page.name
                  )}
                </Button>
              ))}
            </Box>

            <Box sx={{ flexGrow: 0, ml: 2 }}>
              {/* <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt="User Avatar" src="/static/images/avatar/2.jpg" />
              </IconButton>
            </Tooltip> */}
              <Menu
                className="userMenu"
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {settings.map((setting) => (
                  <MenuItem key={setting} onClick={handleCloseUserMenu}>
                    <Typography textAlign="center">{setting}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </div>
  );
};

export default ResponsiveAppBar;
