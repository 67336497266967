import React from "react";

const Whatsapp = ({ children }) => {
  const phoneNumber = "8448985128";
  const message = "Hello, I would like to know more about your services!";

  const whatsappLink = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
    message
  )}`;

  const handleWhatsappRedirect = (e) => {
    e.preventDefault();
    window.open(whatsappLink, "_blank");
  };

  return <div onClick={handleWhatsappRedirect}>{children}</div>;
};

export default Whatsapp;
