import React from "react";
import { useEffect, useRef } from "react";

import "./Home.css";
import { Typography } from "@mui/material";
import WorksCard from "../WorksCard/WorksCard";
import TestimonialCard from "../TestimonialCard/TestimonialCard";
import Whatsapp from "../Whatsapp/Whatsapp";

const Home = () => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    const backgroundImage = new Image();

    backgroundImage.src = "/images/hf-bg-img.png";

    backgroundImage.onload = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
      context.drawImage(backgroundImage, 0, 0, canvas.width, canvas.height);
    };

    const handleResize = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
      context.drawImage(backgroundImage, 0, 0, canvas.width, canvas.height);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="home">
      <canvas ref={canvasRef} className="homeCanvas"></canvas>
      <div className="overlayText">
        <h1>IPTV Brand You Can Trust</h1>
        <p>We Work To Keep You Entertained</p>
        <Whatsapp>
          <button className="homeContactBtn">Get Free Demo</button>
        </Whatsapp>
      </div>

      <div className="homeContainer">
        <Typography variant="h3">Why Choose Us</Typography>
        <div className="homeWorksWrapper">
          <WorksCard
            title="7000+ Live Tv Channels"
            desc="We provide our customers more Live Tv channels than any of our competitor."
          />
          <WorksCard
            title="40000+ VOD / Movies"
            desc="We provide 2x times more movie than Netflix at much less cost."
          />
          <WorksCard
            title="Stable Servers"
            desc="We have more than 20+ servers worldwide for best quality."
          />
          <WorksCard
            title="12 hour free test !"
            desc="Just chat with us down below to get a free test today."
          />
          <WorksCard
            title="Only $100 per Year !!"
            desc="Our service starts at only $100 per year to give best value for your money."
          />
          <WorksCard
            title="Full HD Quality"
            desc="We provide full Hd channels to our customers."
          />
          <WorksCard
            title="24/7 Support"
            desc="Message us on our FB chat to get quick support. We are ready to help you.."
          />
          <WorksCard
            title="All Devices Supported"
            desc="Android/Mag/DreamBox/Engima2/linux/Apple/Samsung/Lg/Vlc and more."
          />
        </div>
      </div>

      <section className="testimonialSection" id="testimonialSection">
        <Typography variant="h3">Testimonials</Typography>
        <Typography variant="h6">What our clients say about us ?</Typography>
        <div className="testimonialWrapper">
          <TestimonialCard
            comment="My wife and kids love there service. It is completely HD and Budget friendly. Also there support is totally awesome"
            name="Peter"
          />
          <TestimonialCard
            comment="I have been looking for a reliable service for so long finally i found them. I just love there service and attitude of the customer support. Never got any issues. Highly recommended."
            name="Mandeep"
          />
          <TestimonialCard
            comment="There service and support are just excellent. I use to pay 150$ to netflix. Now i am paying just 60$ for 3 times more movies. thanks to HealthflixIPTV."
            name="Célia"
          />
          <TestimonialCard
            comment="I have been a customer of Healthflixiptv from 3 years . I there cheap prices and superior quality. lo amo mucho !"
            name="Antonio"
          />
        </div>
      </section>

      <div className="resellerSection">
        <div className="resellerContainer">
          <div className="resellerWrapper">
            <Typography variant="h3">
              Biggest Reseller Hub with over 10k+ active resellers.
            </Typography>
            <Typography>
              if you are planning to become a reseller than look no further.
              Become our reseller and serve your customer quality and trust.
            </Typography>
          </div>
        </div>
        <div className="resellerContainer">
          <div className="resellerWrapper">
            <Typography variant="h3">Budget Friendly Prices</Typography>
            <Typography>
              We believe in quality product and hence we offer the best prices
              that fits everyone budget to get the most premium quality.
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
