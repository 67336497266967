import React, { useState } from "react";
import "./Header.css";
import ResponsiveAppBar from "./muiNav.tsx";

const Header = () => {
  // const [isOpen, setIsOpen] = useState(false);
  // const isSmallScreen = window.innerWidth < 768; // Change this value as needed

  return (
    <ResponsiveAppBar />
    // <nav>
    //   {isSmallScreen ? (
    //     <>
    //       <div className="burger" onClick={() => setIsOpen(!isOpen)}>

    //       </div>
    //       {isOpen && (
    //         <div className="nav-links">
    //           <a href="/">Home</a>
    //           <a href="/about">About</a>
    //           <a href="/contact">Contact</a>
    //         </div>
    //       )}
    //     </>
    //   ) : (
    //     <div className="nav-links">
    //       <a href="/">Home</a>
    //       <a href="/about">About</a>
    //       <a href="/contact">Contact</a>
    //     </div>
    //   )}
    // </nav>
  );
};

export default Header;
