import React from "react";
import { useEffect, useRef } from "react";
import "./ContactUs.css";
// import { Typography } from "@mui/material";
import Whatsapp from "../Whatsapp/Whatsapp";

const ContactUs = () => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    const backgroundImage = new Image();

    backgroundImage.src = "/images/about-img.png";

    backgroundImage.onload = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
      context.drawImage(backgroundImage, 0, 0, canvas.width, canvas.height);
    };

    const handleResize = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
      context.drawImage(backgroundImage, 0, 0, canvas.width, canvas.height);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div className="about">
      <canvas ref={canvasRef} className="aboutCanvas"></canvas>
      <div className="about-overlayText">
        <h1>CONTACT US</h1>
      </div>

      <div className="aboutContainer">
        <Whatsapp>
          <button className="aboutContactBtn">Text on Whatsapp</button>
        </Whatsapp>
        <div className="contDetails">
          <h3>Contact Numbers : </h3>
          <p>8448985128</p>
          <p>9711305547</p>
        </div>
        <div className="contDetails">
          <h3>U.S. Branch Address : </h3>
          <p>30 NGOULD ST STE R SHERIDAN, WY 82801</p>

          <h3>India Branch Address : </h3>
          <p>CV7 1203, Supertech Capetown, Sector 74, Noida, 201304</p>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
