import React from "react";
import { useEffect, useRef } from "react";
import "./About.css";
import { Typography } from "@mui/material";
import Whatsapp from "../Whatsapp/Whatsapp";

const About = () => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    const backgroundImage = new Image();

    backgroundImage.src = "/images/about-img.png";

    backgroundImage.onload = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
      context.drawImage(backgroundImage, 0, 0, canvas.width, canvas.height);
    };

    const handleResize = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
      context.drawImage(backgroundImage, 0, 0, canvas.width, canvas.height);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div className="about">
      <canvas ref={canvasRef} className="aboutCanvas"></canvas>
      <div className="about-overlayText">
        <h1>ABOUT US</h1>
      </div>

      <div className="aboutContainer">
        <Typography>
          Healthflix IPTV provides an excellent collection of content with over
          9000+ Live tv channels and 35k + Vod Content. We strive to be the best
          IPTV service provider by continuously upgrading our servers, so
          customers can get the best experience. Our strong support team and
          technical expertise have allowed us to be the best IPTV service
          provider available worldwide. We provide Worldwide channels, Whether
          it's sports, news, movies, tv shows, we got you covered. We also offer
          catchup for all major Channels so that you never miss out on any show
          and watch it later at your convenience. To get started just ping us on
          Whatsapp and Get your Free 24 hrs demo right away. We support the
          Majority of Devices.
        </Typography>

        <Whatsapp>
          <button className="aboutContactBtn">Get Free Demo</button>
        </Whatsapp>
      </div>
      <h3>Employer Identification Number: 61-2212154</h3>
    </div>
  );
};

export default About;
