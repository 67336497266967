import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// import { useEffect } from "react";
// import { useLocation } from "react-router-dom";
import Home from "./components/Home/Home";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import About from "./components/About/About";
import ContactUs from "./components/ContactUs/ContactUs";

function App() {
  //   const ScrollToTop = () => {
  //     const { pathname, hash } = useLocation();

  //     useEffect(() => {
  //       if (hash) {
  //         const element = document.querySelector(hash);
  //         if (element) {
  //           element.scrollIntoView({ behavior: "smooth" });
  //         }
  //       } else {
  //         window.scrollTo({ top: 0, behavior: "smooth" });
  //       }
  //     }, [pathname, hash]);

  //     return null;
  //   };
  //   ScrollToTop();
  // const [scroll, setScroll] =useState(false);
  // if Route ===

  // useEffect(() => {
  //   window.scrollTo({ top: 0, behavior: "smooth" });
  //   console.log("scrolled to top");
  // }, []);
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<ContactUs />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
