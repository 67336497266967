import React from "react";
import "./WorksCard.css";
import { Typography } from "@mui/material";

const WorksCard = ({ title = "Title here", desc = "Description" }) => {
  return (
    <div className="workCard">
      <div className="content">
        <Typography variant="h5">{title}</Typography>
        <Typography className="desc">{desc}</Typography>
      </div>
    </div>
  );
};

export default WorksCard;
